<template>
  <div class="overview">
    <GrowthSectionHeader :criteria="criteria" :showShare="false" />
    <c-flex v-if="!isShareView" justify="left" align="center">
      <AppSegment
        @input="handleChange"
        :segments="segments"
        name="view"
        v-model="currentView"
        :disabled="disableTabs"
      />
    </c-flex>
    <div class="content">
      <div class="content__results">
        <div
          v-if="isLoading"
          class="load"
          style="
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
          "
        >
          <div class="spinner"></div>
          Generating, {{ currentView }} Please wait ...
        </div>
        <div v-else>
          <c-text
            as="span"
            color="#ef5323"
            fontSize="14px"
            v-if="saved && currentView === 'pitch'"
            ><b>Saved successfully 👍</b></c-text
          >
          <div
            v-if="showBlogKeyword && currentView === 'blog-post'"
            style="display: flex; align-items: center; margin-bottom: 20px"
          >
            <c-input
              placeholder="Whats your blog post about(ex: How to get more leads)"
              v-model="blogKeyword"
            />
            <c-button
              @click="generateBlog"
              size="sm"
              px="5"
              ml="5"
              variant-color="vc-orange"
              variant="outline"
              :disabled="blogKeyword === ''"
            >
              Generate
            </c-button>
          </div>

          <div>
            <blockquote contenteditable="true" @blur="updateResults">
              <p v-html="result"></p>
            </blockquote>
          </div>

          <!-- <EditableText title="Generate" v-model="result" /> -->
        </div>
      </div>
      <div class="content__controls">
        <c-button
          size="md"
          px="8"
          @click="handleAlternate()"
          variant-color="vc-orange"
          variant="outline"
          :disabled="this.result === ''"
        >
          Alternate
        </c-button>
        <c-button
          @click="handleShort()"
          size="md"
          px="8"
          mt="2"
          variant-color="vc-orange"
          variant="outline"
          :disabled="this.result === ''"
        >
          Sentence
        </c-button>

        <c-button
          @click="handleBullet()"
          size="md"
          px="12"
          mt="2"
          variant-color="vc-orange"
          variant="outline"
          :disabled="this.result === ''"
        >
          Bullet
        </c-button>
        <c-button
          @click="handleSentence()"
          size="md"
          px="4"
          mt="2"
          variant-color="vc-orange"
          variant="outline"
          :disabled="this.result === ''"
        >
          Short Answer
        </c-button>

        <c-button
          @click="copyToClipboard(result)"
          size="md"
          px="11"
          mt="6"
          variant-color="vc-orange"
          variant="outline"
          :disabled="this.result === ''"
        >
          copy
          <c-text ml="1" fontSize="1.5em" color="#ef5323">+</c-text>
        </c-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import GrowthSectionHeader from './../components/GrowthSectionHeader';
import AppSegment from './../components/AppSegment.vue';
// import EditableText from './../components/EditableText.vue';
import { getCompany, updateCompany } from '@/services/company';
import { getAI } from '../../../../services/openAI';
import foundationMixin from '@/mixins/foundation.js';
import businessModelMixin from '@/mixins/business-model.js';
import businessMixin from '@/mixins/business-model.js';
import { getBusinessModelByType } from '@/services/business-model.js';

export default {
  mixins: [foundationMixin, businessModelMixin, businessMixin],

  props: {
    activeView: {
      type: String,
      default: '',
    },
  },
  components: {
    GrowthSectionHeader,
    AppSegment,
    // EditableText,
  },
  data() {
    return {
      foundationType: 'vision',
      businessModelType: 'overall_problem',
      businessSection: 'overall_solution',
      targetMarkets: [],
      isShareOpen: false,
      criteria: {
        title: 'Generate',
        description: `The generate provides you with an AI powered generate feature. This feature generates blog posts, name suggestions, slogans, buzzwords and tweet suggestions.
                      `,
      },
      result: 'Content here',
      isLoading: false,
      segments: [
        { title: 'Elevator Pitch', value: 'pitch' },
        { title: 'Blog post', value: 'blog-post' },
        { title: 'Name suggestions', value: 'name' },
        { title: 'Slogans', value: 'slogan' },
        { title: 'Buzzwords', value: 'buzzwords' },
        { title: 'Tweets suggestion', value: 'tweets' },
        { title: 'Social Hashtags', value: 'hashtags' },
        { title: 'Business Report', value: 'report' },
      ],
      currentView: '',
      disableTabs: false,
      saved: false,
      blogKeyword: '',
      showBlogKeyword: false,
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
      isShareView: (state) => state.isShareView,
      customers: (state) => state.customers,
    }),
  },
  created() {
    if (this.activeView) {
      this.currentView = this.activeView;
    }
    // this.getCustomers();
    this.getBusinessSectionStatement();
  },
  mounted() {
    if (this.isShareView) {
      this.setCompany();
    }
  },
  watch: {
    currentView() {
      this.isLoading = false;
      this.result = '';
      this.disableTabs = true;
      if (this.currentView === 'blog-post') {
        this.disableTabs = false;
        this.showBlogKeyword = true;
        // this.generateBlog();
      } else if (this.currentView === 'name') {
        this.generateNameSuggestions();
      } else if (this.currentView === 'slogan') {
        this.generateSlogans();
      } else if (this.currentView === 'buzzwords') {
        this.generateBuzzwords();
      } else if (this.currentView === 'tweets') {
        this.generateTweetSuggestions();
      } else if (this.currentView === 'hashtags') {
        this.generateHashTags();
      } else if (this.currentView === 'report') {
        this.generateBusinessReport();
      } else if (this.currentView === 'pitch') {
        // this.generateElevatorPitch();
        this.disableTabs = false;
        this.result = this.activeCompany.elevatorPitch;
      }
    },
  },
  methods: {
    ...mapActions({
      setActiveCompany: 'company/setActiveCompany',
    }),
    async updateResults(event) {
      this.content = event.target.textContent;
      if (this.currentView === 'pitch') {
        try {
          await updateCompany({
            id: this.activeCompany.id,
            set: { elevatorPitch: this.content },
          });
          this.saved = true;
        } catch (e) {
          this.$toast({
            title: 'An error occurred.',
            description: `Error while updating elevator pitch, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },
    handleChange(s) {
      this.currentView = s;
    },
    // getCustomers() {
    //   getCustomers(this.activeCompany.id).then((res) => {
    //     this.setCustomers(res.data.customer);
    //     this.targetMarket = res.data.customer[0].name;
    //   });
    // },
    async generateBlog() {
      this.isLoading = true;
      this.disableTabs = true;

      // if (this.businessModelData.description === undefined) {
      //   this.isLoading = true;
      //   setTimeout(this.generateBlog, 2000);
      // }
      try {
        const res = await getAI({
          context: `If this is my business problem "${this.businessModelData.description}" and solution is ${this.businessSection}. This is my vision ${this.foundationData.description}. Generate a blog post based on this keyword "${this.blogKeyword}"  Return results in html format with styling`,
        });
        this.result = res.data.data;
        this.disableTabs = false;
        this.isLoading = false;
      } catch (error) {
        this.disableTabs = false;
        this.isLoading = false;
        console.log(error);
        this.$toast({
          title: 'Error',
          description: `Sorry an error has occured `,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      }
    },

    async generateNameSuggestions() {
      this.isLoading = true;

      try {
        const res = await getAI({
          context: `This is my problem "${this.businessModelData.description}", and this is my vision ${this.foundationData.description}. Suggest to me some company names i can use. Should be in html`,
        });
        this.result = res.data.data;
        this.disableTabs = false;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.disableTabs = false;
        console.log(error);
        this.$toast({
          title: 'Error',
          description: `Sorry an error has occured `,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      }
    },

    async generateSlogans() {
      this.isLoading = true;

      try {
        const res = await getAI({
          context: `This is my problem "${this.businessModelData.description}", and this is my vision ${this.foundationData.description}. Suggest to me some slogans i can use. Should be in html`,
        });
        this.result = res.data.data;
        this.isLoading = false;
        this.disableTabs = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
        this.$toast({
          title: 'Error',
          description: `Sorry an error has occured `,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      }
    },

    async generateBuzzwords() {
      this.isLoading = true;

      try {
        const res = await getAI({
          context: `This is my problem "${this.businessModelData.description}", and this is my vision ${this.foundationData.description}. Genarate buzzwords i can use. Should be in html`,
        });
        this.result = res.data.data;
        this.disableTabs = false;
        this.isLoading = false;
      } catch (error) {
        this.disableTabs = false;
        this.isLoading = false;
        console.log(error);
        this.$toast({
          title: 'Error',
          description: `Sorry an error has occured `,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      }
    },

    async generateTweetSuggestions() {
      this.isLoading = true;

      try {
        const res = await getAI({
          context: `This is my problem "${this.businessModelData.description}", and this is my vision ${this.foundationData.description}. Genarate tweet suggestions i can use for my company. Should be in html`,
        });
        this.result = res.data.data;
        this.disableTabs = false;
        this.isLoading = false;
      } catch (error) {
        this.disableTabs = false;
        this.isLoading = false;
        console.log(error);
        this.$toast({
          title: 'Error',
          description: `Sorry an error has occured `,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      }
    },

    async generateHashTags() {
      this.isLoading = true;

      try {
        const res = await getAI({
          context: `This is my problem "${this.businessModelData.description}", and this is my vision ${this.foundationData.description}. Genarate hashtags i can use for my company.`,
        });
        this.result = res.data.data;
        this.disableTabs = false;
        this.isLoading = false;
      } catch (error) {
        this.disableTabs = false;
        this.isLoading = false;
        console.log(error);
        this.$toast({
          title: 'Error',
          description: `Sorry an error has occured `,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async generateElevatorPitch() {
      this.isLoading = true;
      // get this.customers[i].name in a string with coma seperated
      let customers = '';
      for (let i = 0; i < this.customers.length; i++) {
        customers += `${this.customers[i].name}, `;
      }

      try {
        const res = await getAI({
          context: `This is my problem "${this.businessModelData.description}". This is my solution ${this.businessSection}. This is my vision ${this.foundationData.description}. This are my target markets "${customers}". Genarate an elevator pitch i can use for my company. Should be formmatted in html.`,
        });
        this.result = res.data.data;
        this.disableTabs = false;
        this.isLoading = false;
      } catch (error) {
        this.disableTabs = false;
        this.isLoading = false;
        console.log(error);
        this.$toast({
          title: 'Error',
          description: `Sorry an error has occured `,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      }
    },
    getBusinessSectionStatement() {
      getBusinessModelByType({
        type: 'overall_solution',
        companyId: this.activeCompany.id,
      })
        .then((res) => {
          if (res.data.business_model.length) {
            this.businessSection = res.data.business_model[0].description;
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    async generateBusinessReport() {
      this.isLoading = true;
      // get this.customers[i].name in a string with coma seperated
      let customers = '';
      for (let i = 0; i < this.customers.length; i++) {
        customers += `${this.customers[i].name}, `;
      }

      try {
        const res = await getAI({
          context: `This is my problem "${this.businessModelData.description}". This is my solution ${this.businessSection}. This is my vision ${this.foundationData.description}. This are my target markets "${customers}". Genarate a business report i can use for my company. Should be formmatted in html.`,
        });
        this.result = res.data.data;
        this.disableTabs = false;
        this.isLoading = false;
      } catch (error) {
        this.disableTabs = false;
        this.isLoading = false;
        console.log(error);
        this.$toast({
          title: 'Error',
          description: `Sorry an error has occured `,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      }
    },

    async handleAlternate() {
      this.isLoading = true;

      if (this.currentView === 'pitch') {
        this.generateElevatorPitch();
      } else {
        try {
          const res = await getAI({
            context: `Get an alternate of "${this.result}" in html`,
          });
          this.result = res.data.data;
          this.disableTabs = false;
          this.isLoading = false;
        } catch (error) {
          this.disableTabs = false;
          this.isLoading = false;
          console.log(error);
          this.$toast({
            title: 'Error',
            description: `Sorry an error has occured `,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },

    async handleBullet() {
      this.isLoading = true;

      try {
        const res = await getAI({
          context: `Get this "${this.result}" in bullet points in html`,
        });
        this.result = res.data.data;
        this.disableTabs = false;
        this.isLoading = false;
      } catch (error) {
        this.disableTabs = false;
        this.isLoading = false;
        console.log(error);
        this.$toast({
          title: 'Error',
          description: `Sorry an error has occured `,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      }
    },

    async handleShort() {
      this.isLoading = true;

      try {
        const res = await getAI({
          context: `Get this "${this.result}" in one sentence in html`,
        });
        this.result = res.data.data;
        this.disableTabs = false;
        this.isLoading = false;
      } catch (error) {
        this.disableTabs = false;
        this.isLoading = false;
        console.log(error);
        this.$toast({
          title: 'Error',
          description: `Sorry an error has occured `,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async handleSentence() {
      this.isLoading = true;

      try {
        const res = await getAI({
          context: `Get a short version of "${this.result}" in html `,
        });
        this.result = res.data.data;
        this.disableTabs = false;
        this.isLoading = false;
      } catch (error) {
        this.disableTabs = false;
        this.isLoading = false;
        console.log(error);
        this.$toast({
          title: 'Error',
          description: `Sorry an error has occured `,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      }
    },

    async copyToClipboard(code) {
      try {
        await navigator.clipboard.writeText(String(code));
        this.$toast({
          title: 'Success.',
          description: ` Copied successfully.`,
          position: 'top',
          status: 'success',
          duration: 5000,
        });
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    },

    async setCompany() {
      await getCompany(this.$route.query.company_id).then((res) => {
        this.setActiveCompany(cloneDeep(res.data.company_by_pk));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  @apply mt-12 py-6  flex rounded-sm h-full place-content-center;
  background-color: #eaf0f6;
  min-height: 300px;

  .content__results {
    @apply w-4/5 py-8 px-16;
  }

  .content__controls {
    @apply px-12;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
.content-2 {
  @apply flex rounded-sm justify-between place-content-center;
  background-color: #eaf0f6;
}

.spinner {
  width: 88px;
  height: 88px;
  display: grid;
  animation: spinner-plncf9 12.2s infinite;
}

.spinner::before,
.spinner::after {
  content: '';
  grid-area: 1/1;
  border: 8.1px solid;
  border-radius: 50%;
  border-color: #fefefe #fefefe #0000 #0000;
  mix-blend-mode: darken;
  animation: spinner-plncf9 0.8s infinite linear;
}
.load {
}

.spinner::after {
  border-color: #0000 #0000 #dbdcef #dbdcef;
  animation-direction: reverse;
}

@keyframes spinner-plncf9 {
  100% {
    transform: rotate(1turn);
  }
}

blockquote {
  background: white;
  border-radius: 2px;
  margin: 16px 0;
  border: 1px solid #eaf0f6;
  height: auto;
}

blockquote p {
  padding: 15px;
}

[contenteditable='true'] {
  caret-color: red;
}
</style>
